import {formatAsNumber} from '@/Scripts/Common/formats.jsx';
import {TextField} from '@mui/material';
import * as React from 'react';
import {forwardRef, useEffect, useState} from 'react';

const NumberTextField = forwardRef(({id, name, value = '', onChange, ...props}, ref) => {
    const [formattedValue, setFormattedValue] = useState(value);

    useEffect(() => {
        if (value !== formattedValue) {
            const new_value = formatAsNumber(value) ?? value ?? '';
            setFormattedValue(new_value);
        }
    }, [value]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const formattedInputValue = formatAsNumber(inputValue) ?? inputValue;

        setFormattedValue(formattedInputValue);

        onChange({
            name,
            value: inputValue,
        });
    };

    return <TextField ref={ref} type="text" value={formattedValue} onChange={handleInputChange} {...props} />;
});

export default NumberTextField;
